<template>
  <div class="qr-popover">
    <ion-list v-if="title || content">
      <ion-list-header lines="full" v-if="title">
        <ion-text color="dark">
          <h3 class="mt-1">{{ title }}</h3>
        </ion-text>
      </ion-list-header>
      <ion-item lines="none" v-if="content">
        <div class="ion-item-content" v-html="content" />
      </ion-item>
    </ion-list>
  </div>
</template>

<script lang="ts">
import { IonList, IonListHeader, IonText, IonItem } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Popover-Message-QR',
  components: {
    IonList, IonListHeader, IonText, IonItem
  },
  props: {
    title: {
      type: String,
      required: false
    },
    content: {
      type: String,
      required: false
    }
  },
});
</script>

<style lang="less">
.qr-popover {
  --max-width: 100%;
  --min-width: unset;
  --width: 300px;
}
</style>