import { Storage } from '@ionic/storage'

let cache = null

export async function initCache () {
    cache = new Storage()
    let r = await cache.create()
    // cache.forEach((key, value, index) => {
    //     console.log('[API cache]', key, value, index)
    // })
    return r
}

export { cache as default }
