<template>
  <ion-tabs
      class="app-navigation"
      @ionTabsDidChange="afterTabChange($event)"
      ref="appNav"
  >
    <ion-router-outlet />
    <ion-tab-bar
        slot="bottom"
        class="ion-bg-black"
    >

      <ion-tab-button
          v-for="(button, index) in navigation"
          :tab="button.tab"
          :key="`app-navigation-${index}`"
          :href="button.href"
          @click="checkTab(button.tab)"
      >
        <ion-icon :src="getIconSrc(button.iconName)" />
        <ion-label
            class="ff-bonn-plus"
            v-html="$t(button.text)"
        />
      </ion-tab-button>

    </ion-tab-bar>
  </ion-tabs>
</template>

<script lang="ts">
import { IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon } from '@ionic/vue';
// @ts-ignore
import viewHelperMethods from '@/helper/view'

export default {
  name: 'App-Navigation',
  components: { IonRouterOutlet, IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon },
  data () {
    return {
      tabButtons: [
        {
          tab: "exhibitions",
          href: "/tabs/exhibitions",
          iconName: "nav-exhibitions-white",
          text: "appNav.exhibition"
        },
        {
          tab: "search",
          href: "/tabs/search",
          iconName: "nav-events-white",
          text: "appNav.events"
        },
        {
          tab: "services",
          href: "/tabs/services",
          iconName: "nav-services-white",
          text: "appNav.services"
        }
      ],
      clickedTab: ""
    }
  },
  computed: {
    navigation (): any {
      return (this as any).updateNavigation();
    }
  },
  methods: {
    ...viewHelperMethods,
    updateNavigation() {
      const vm = (this as any);
      let authTab = {
        tab: "auth",
        href: "",
        iconName: "nav-account-white",
        text: ""
      };

      if (localStorage.getItem('jwt') !== null) {
        authTab.href = "/tabs/account";
        authTab.text = "appNav.account"
      } else {
        authTab.href = "/tabs/auth/login";
        authTab.text = "appNav.login"
      }
      return [...vm.tabButtons, authTab];
    },
    checkTab(tab: string) {
      const vm = (this as any);
      if(vm.clickedTab == tab)
        vm.$bus.emit("scrollToTop", {state: true, speed: 350});
    },
    afterTabChange(event: any) {
      const vm = (this as any);
      vm.updateNavigation();
      vm.clickedTab = event.tab;
    }
  }
}
</script>

<style lang="less">
.app-navigation {

  > ion-tab-bar {
    height: 70px;
    padding: 0 15px;

    ion-icon {
      width: 40px;
      height: 40px;
      margin: 0 0 3px;
      background: #000;
    }

    ion-label {
      font-size: 10px;
      line-height: 9px;
      letter-spacing: 0.6px;
    }

    ion-tab-button {
      background-color: transparent;

      &::part(native) {
        padding: 0;
        color: #fff !important;
      }
    }
  }
}
</style>