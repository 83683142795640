import axios from 'axios'
import cache from '@/service/cache.js'

const axiosHeaders = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

const api = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: axiosHeaders
})

function hashAsync(str) {
  if (!window.crypto || !window.crypto.subtle || !window.TextEncoder) {
    return str
  }
  return window.crypto.subtle.digest("SHA-256", new TextEncoder("utf-8").encode(str)).then(buf => {
    return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
  });
}

function getCachKeyFromConfig(config) {
  const cacheUrl = `/${config.url}?hl=${config.params.hl}`
  return cacheUrl.replace(/[^a-zA-Z0-9]/g, '_')
}

api._get = api.get
api.get = async function (...args) {
  const appLanguage = localStorage.getItem('locale') || 'en'
  const config = {
    url: arguments[0], // eslint-disable-line prefer-rest-params
    params: {
      hl: appLanguage.substring(0, 2)
    }
  }
  // check for cached responses, in case user is offline
  if (!window.navigator.onLine) {
    const cacheKey = getCachKeyFromConfig(config)
    let cachedResponse = false
    try {
      cachedResponse = await cache.get(cacheKey)
    } catch (e) { console.error(e) }

    if (cachedResponse) {
      console.log('user is offline, but cached GET response available', cacheKey, JSON.parse(cachedResponse))
      return JSON.parse(cachedResponse)
    } else {
      Promise.reject({
        offline: true
      })
    }
  }

  return api._get(...args)
}

// Response interceptor
api.interceptors.response.use(
  async (response) => {
    // cache responses for any GET request locally
    if (response.config.method.toUpperCase() === 'GET') {
      const cacheKey = getCachKeyFromConfig(response.config)
      try {
        cache.set(cacheKey, JSON.stringify(response))
      } catch(e) {
        console.error(e)
      }
      
      console.log('Axios response interceptor, caching response:', cacheKey, response)
    }

    return response
  },
  (error) => {
    Promise.reject(error)
  }
)

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    // set language
    let appLanguage = localStorage.getItem('locale') || 'en';
    config.params = config.params || {};
    config.params['hl'] = appLanguage.substring(0, 2);

    // activate default no-cache for any GET request on DEV
    if (
      config.method.toUpperCase() === 'GET' &&
      process.env.VUE_APP_RELEASE_CHANNEL !== 'PROD'
    ) {
      config.params['noCache'] = 1
    }

    // add BEARER auth header for logged in users
    let jwtToken = localStorage.getItem('jwt') || null;
    if (jwtToken !== null) {
      config.headers.common.Authorization = `Bearer ${jwtToken}`
    } else {
      delete config.headers.common.Authorization
    }

    // create request hash (to secure API calls)
    const superSecretSalt = "!gd'967uHvm*w.X!Jh#*1LE>_X8ybA6+"   
    // if (config.method.toUpperCase() === 'POST' || config.method.toUpperCase() === 'DELETE') {
      let hash = (!config.data ? '' : JSON.stringify(config.data)) + superSecretSalt
      let outputHash = await hashAsync(hash)
      console.log('JS sha-256 for ', hash)
      console.log(outputHash)
      console.log('[axios] POST Hash', outputHash)
      config.headers.common['X-BUKU-REQUEST-HASH'] = outputHash
    // }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default api