<template>
  <ion-page>
    <ion-content>
      <native-app-modal />
      <app-navigation />
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from '@ionic/vue'

import AppNavigation from "@/components/navigation/app-navigation.vue"
import NativeAppModal from '@/components/misc/native-app-modal.vue'

export default {
  name: 'Tabs',
  components: { 
    AppNavigation, 
    NativeAppModal,
    IonContent, 
    IonPage 
  }
}
</script>