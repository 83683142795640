export const consentOptions = {
    languages: {
        'de': {
            consent_modal: {
                revision: 1,
                revision_message: '<br><br> Dear user, terms and conditions have changed since the last time you visisted!',
                title: 'WIR LIEBEN COOKIES!',
                description: 'Wir verwenden essentiell notwendige und sogenannte funktionale Cookies; z.B. für die Anzeige von Social Media Inhalten. Letztere werden nur nach Ihrem Einverständnis verwendet. Wir verwenden keine Tracking-Cookies! Weitere Hinweise finden Sie in den <a href="/tabs/privacy-policy" class="cc-link">Datenschutzhinweisen</a>.',
                primary_btn: {
                    text: 'Alle akzeptieren',
                    role: 'accept_all' //'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Nur essentiell notwendige Cookies akzeptieren',
                    role: 'accept_necessary' //'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie Einstellungen',
                save_settings_btn: 'Auswahl speichern',
                accept_all_btn: 'Alle akzeptieren',
                reject_all_btn: 'Alle ablehnen',
                close_btn_label: 'Schließen',
                cookie_table_headers: [
                    {
                        col1: 'Dienstanbieter'
                    },
                    {
                        col2: 'Name'
                    },
                    {
                        col3: 'Domain'
                    },
                    {
                        col4: 'Speicherdauer'
                    },
                    {
                        col5: 'Beschreibung'
                    }
                ],
                blocks: [
                    {
                        title: 'Cookie Nutzung',
                        description: 'Weitere Informationen zu Cookies finden Sie in den <a href="/tabs/privacy-policy" class="cc-link">Datenschutzhinweisen</a>.'
                    }, 
                    {
                        title: 'Analyse-Cookies',
                        description: 'Diese Cookies sind notwendig, um anonymisierte Informationen über Ihre Nutzung der App zu erhalten, damit wir unser Angebot noch besser auf Ihre Bedürnisse zuschneiden können.',
                        toggle: {
                            value: 'analytical',
                            enabled: true,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Bundeskunsthalle',
                                col2: '_pk_id.*',
                                col3: 'localhost',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie dient der Identifizierung eines bekannten Geräts.'
                            },
                            {
                                col1: 'Bundeskunsthalle',
                                col2: '_pk_ses.*',
                                col3: 'localhost',
                                col4: '24 Stunden',
                                col5: 'Dieses Cookie dient der Identifizierung einer Nutzersitzung.'
                            },
                            {
                                col1: 'Bundeskunsthalle',
                                col2: 'MATOMO_SESSID',
                                col3: 'webstatistik.uk-koeln.de',
                                col4: '14 Tage',
                                col5: 'Dieses Cookie dient der Identifizierung einer Nutzersitzung.'
                            }
                        ]
                    },
                    {
                        title: 'Essentielle Cookies',
                        description: 'Diese Cookies sind unerlässlich für die Nutzung der App.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true //cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                            {
                                col1: 'Bundeskunsthalle',
                                col2: 'jwt',
                                col3: 'localhost',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie dient der Authentifizierung eingeloggter Benutzer.'
                            },
                            {
                                col1: 'Bundeskunsthalle',
                                col2: 'jwt_expires',
                                col3: 'localhost',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie dient der Authentifizierung eingeloggter Benutzer.'
                            },
                            {
                                col1: 'Bundeskunsthalle',
                                col2: 'cc_consent',
                                col3: 'localhost',
                                col4: 'unbegrenzt',
                                col5: 'Dieses Cookie enthält die Informationen zu den Cookie-Einstellungen des Benutzers.'
                            }
                        ]
                    },
                    {
                        title: 'Funktionale Cookies',
                        description: 'Diese Cookies sind für den Versand von Push Benachrichtigungen sowie die Integration von Social Media Diensten notwendig.',
                        toggle: {
                            value: 'functional',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Google Inc.',
                                col2: 'token',
                                col3: '.google.com',
                                col4: '2 Jahre',
                                col5: 'Dieses Cookie ist für den Versand von Push Benachrichtigungen notwendig und dient der Identifizierung eines bekannten Geräts.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'kdt',
                                col3: '.twitter.com',
                                col4: '2 Jahre',
                                col5: 'Dieses Cookie ist für die Authentifizierung eines bekannten Geräts.',
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'auth_token',
                                col3: '.twitter.com',
                                col4: '5 Jahre',
                                col5: 'Dieses Cookie ist für den Login in den Account und die Authentifizierung.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'twid',
                                col3: '.twitter.com',
                                col4: '5 Jahre',
                                col5: 'Dieses Cookie ist für die Authentifizierung.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'ct0',
                                col3: '.twitter.com',
                                col4: '6 Stunden',
                                col5: 'Dieses Cookie ist für die Authentifizierung.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'guest_id',
                                col3: '.twitter.com',
                                col4: '2 Jahre',
                                col5: 'Dieses Cookie ist für die Authentifizierung.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'des_opt_in',
                                col3: '.twitter.com',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie ist für die Cookie-Erklärungen.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'eu_cn',
                                col3: '.twitter.com',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie ist für die Cookie-Erklärungen.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'dnt',
                                col3: '.twitter.com',
                                col4: '5 Jahre',
                                col5: 'Dieses Cookie ist für die Ablehnung von Personalisierung von Werbeanzeigen.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: '_twitter_sess',
                                col3: '.twitter.com',
                                col4: 'Session',
                                col5: 'Dieses Cookie ist für Nutzersitzungen.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'usida',
                                col3: '.facebook.com',
                                col4: 'Session',
                                col5: 'Dieses Cookie dient den Werbeeinstellungen.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'fr',
                                col3: '.facebook.com',
                                col4: '14 Tage',
                                col5: 'Dieses Cookie dient der personalisierten Werbung.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'sb',
                                col3: '.facebook.com',
                                col4: '18 Monate',
                                col5: 'Dieses Cookie identifiziert den Browser.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'presence',
                                col3: '.facebook.com',
                                col4: 'Session',
                                col5: 'Dieses Cookie dient der Chat Funktion.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'datr',
                                col3: '.facebook.com',
                                col4: '18 Monate',
                                col5: 'Dieses Cookie identifiziert den Browser.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'c_user',
                                col3: '.facebook.com',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie ist für die Authentifizierung.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'xs',
                                col3: '.facebook.com',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie dient der Secure-Browsing Funktion.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'mid',
                                col3: '.instagram.com',
                                col4: '18 Monate',
                                col5: 'Dieses Cookie dient der Sicherheit.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'id_did',
                                col3: '.instagram.com',
                                col4: '18 Monate',
                                col5: 'Dieses Cookie ermöglicht die Einbindung des Instagram-Widgets.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'rur',
                                col3: '.instagram.com',
                                col4: 'Session',
                                col5: 'Dieses Cookie dient der Sicherheit.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'fbsr_*',
                                col3: '.instagram.com',
                                col4: 'Session',
                                col5: 'Dieses Cookie dient der Sicherheit.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'csrftoken',
                                col3: '.instagram.com',
                                col4: '9 Monate',
                                col5: 'Dieses Cookie dient der Sicherheit.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'ds_user_id',
                                col3: '.instagram.com',
                                col4: '14 Tage',
                                col5: 'Dieses Cookie ist für die Authentifizierung.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'sessionid',
                                col3: '.instagram.com',
                                col4: '9 Monate',
                                col5: 'Dieses Cookie ist für die Authentifizierung.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SIDCC',
                                col3: '.google.com',
                                col4: '12 Monate',
                                col5: 'Sicherheits-Cookie von Google, um Nutzer zu authentifizieren, betrügerische Verwendung von Anmeldeinformationen zu verhindern und Nutzerdaten vor dem Zugriff durch Unbefugte zu schützen.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: '1P_JAR',
                                col3: '.google.com',
                                col4: '1 Monat',
                                col5: 'Dieser Cookie wird zur Optimierung von Werbung eingesetzt, um für Nutzer relevante Anzeigen bereitzustellen, Berichte zur Kampagnenleistung zu verbessern oder um zu vermeiden, dass ein Nutzer dieselben Anzeigen mehrmals sieht.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'NID',
                                col3: '.google.com',
                                col4: '6 Monate',
                                col5: ''
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'AEC',
                                col3: '.google.com',
                                col4: '12 Monate',
                                col5: ''
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SID',
                                col3: '.google.com',
                                col4: '24 Monate',
                                col5: 'Sicherheits-Cookie von Google, um Nutzer zu authentifizieren, betrügerische Verwendung von Anmeldeinformationen zu verhindern und Nutzerdaten vor dem Zugriff durch Unbefugte zu schützen.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'APISID',
                                col3: '.google.com',
                                col4: '24 Monate',
                                col5: 'Sicherheits-Cookie von Google, um Nutzer zu authentifizieren, betrügerische Verwendung von Anmeldeinformationen zu verhindern und Nutzerdaten vor dem Zugriff durch Unbefugte zu schützen.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'CONSENT',
                                col3: '.google.com',
                                col4: '16 Jahre',
                                col5: 'Dieses Cookie ist für die Cookie-Erklärungen.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SAPISID',
                                col3: '.google.com',
                                col4: '24 Monate',
                                col5: 'Sicherheits-Cookie von Google, um Nutzer zu authentifizieren, betrügerische Verwendung von Anmeldeinformationen zu verhindern und Nutzerdaten vor dem Zugriff durch Unbefugte zu schützen.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SSID',
                                col3: '.google.com',
                                col4: '24 Monate',
                                col5: 'Sicherheits-Cookie von Google, um Nutzer zu authentifizieren, betrügerische Verwendung von Anmeldeinformationen zu verhindern und Nutzerdaten vor dem Zugriff durch Unbefugte zu schützen.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SAPISID',
                                col3: '.youtube.com',
                                col4: '24 Monate',
                                col5: 'Sicherheits-Cookie von Google, um Nutzer zu authentifizieren, betrügerische Verwendung von Anmeldeinformationen zu verhindern und Nutzerdaten vor dem Zugriff durch Unbefugte zu schützen.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SID',
                                col3: '.youtube.com',
                                col4: '24 Monate',
                                col5: 'Sicherheits-Cookie von Google, um Nutzer zu authentifizieren, betrügerische Verwendung von Anmeldeinformationen zu verhindern und Nutzerdaten vor dem Zugriff durch Unbefugte zu schützen.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'APISID',
                                col3: '.youtube.com',
                                col4: '24 Monate',
                                col5: 'Sicherheits-Cookie von Google, um Nutzer zu authentifizieren, betrügerische Verwendung von Anmeldeinformationen zu verhindern und Nutzerdaten vor dem Zugriff durch Unbefugte zu schützen.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SIDCC',
                                col3: '.youtube.com',
                                col4: '12 Monate',
                                col5: 'Sicherheits-Cookie von Google, um Nutzer zu authentifizieren, betrügerische Verwendung von Anmeldeinformationen zu verhindern und Nutzerdaten vor dem Zugriff durch Unbefugte zu schützen.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'CONSENT',
                                col3: '.youtube.com',
                                col4: '16 Jahre',
                                col5: 'Dieses Cookie ist für die Cookie-Erklärungen.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'LOGIN_INFO',
                                col3: '.youtube.com',
                                col4: '18 Monate',
                                col5: 'Dieses Cookie ist für die Authentifizierung.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'PREF',
                                col3: '.youtube.com',
                                col4: '1 Monat',
                                col5: 'Wird von YouTube (Google) zum Speichern von Benutzereinstellungen und anderen nicht angegebenen Zwecken verwendet.'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'OptanonAlertBoxClosed',
                                col3: '.spotify.com',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie ist für die Cookie-Erklärungen'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'OptanonConsent',
                                col3: '.spotify.com',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie ist für die Cookie-Erklärungen'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'eupubconsent-v2',
                                col3: '.spotify.com',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie ist für die Cookie-Erklärungen'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'sp_dc',
                                col3: '.spotify.com',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie dient der Einbundung des Spotify-Widgets.'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'sp_key',
                                col3: '.spotify.com',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie dient der Einbundung des Spotify-Widgets.'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'sp_m',
                                col3: '.spotify.com',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie dient der Einbundung des Spotify-Widgets.'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'sp_t',
                                col3: '.spotify.com',
                                col4: '12 Monate',
                                col5: 'Dieses Cookie dient der Einbundung des Spotify-Widgets.'
                            }
                        ]
                    }
                ]
            }
        },
        'en': {
            consent_modal: {
                revision: 1,
                revision_message: '<br><br> Dear user, terms and conditions have changed since the last time you visisted!',
                title: 'WE LOVE COOKIES!',
                description: 'This app uses cookies, which are essential for the use of the app. In addition, we use so-called functional cookies, e.g. to display social media content. The latter will be set only after consent. We don\'t use any tracking cookies! You can find further information in our <a href="/tabs/privacy-policy" class="cc-link">privacy policy</a>.',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all' //'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Preferences',
                    role: 'settings' //'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie settings',
                save_settings_btn: 'Save selection',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {
                        col1: 'Service Provider'
                    },
                    {
                        col2: 'Name'
                    },
                    {
                        col3: 'Domain'
                    },
                    {
                        col4: 'Expire time'
                    },
                    {
                        col5: 'Description'
                    }
                ],
                blocks: [
                    {
                        title: 'Cookie usage',
                        description: 'For more information about cookies, see the <a href="/tabs/privacy-policy" class="cc-link">privacy policy</a>.'
                    },
                    {
                        title: 'Strictly necessary cookies',
                        description: '',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true //cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                            {
                                col1: 'Bundeskunsthalle',
                                col2: 'jwt',
                                col3: 'localhost',
                                col4: '12 months',
                                col5: 'This cookie authenticates the logged in user.'
                            },
                            {
                                col1: 'Bundeskunsthalle',
                                col2: 'jwt_expires',
                                col3: 'localhost',
                                col4: '12 months',
                                col5: 'This cookie authenticates the logged in user.'
                            },
                            {
                                col1: 'Bundeskunsthalle',
                                col2: 'cc_consent',
                                col3: 'localhost',
                                col4: 'unlimited',
                                col5: 'This cookie contains the information about the user\'s cookie settings.'
                            }
                        ]
                    }, 
                    {
                        title: 'Analytical Cookies',
                        description: 'These cookies are required to anonymiously track app usage, like visited pages, time spent on page etc.',
                        toggle: {
                            value: 'analytical',
                            enabled: true,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Bundeskunsthalle',
                                col2: '_pk_id.*',
                                col3: 'localhost',
                                col4: '12 months',
                                col5: 'Dieses Cookie dient der Identifizierung eines bekannten Geräts.'
                            },
                            {
                                col1: 'Bundeskunsthalle',
                                col2: '_pk_ses.*',
                                col3: 'localhost',
                                col4: '24 months',
                                col5: 'Dieses Cookie dient der Identifizierung einer Nutzersitzung.'
                            },
                            {
                                col1: 'Bundeskunsthalle',
                                col2: 'MATOMO_SESSID',
                                col3: 'bundeskunsthalle.de',
                                col4: '14 days',
                                col5: 'Dieses Cookie dient der Identifizierung einer Nutzersitzung.'
                            }
                        ]
                    },
                    {
                        title: 'Functional cookies',
                        description: '',
                        toggle: {
                            value: 'functional',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Google Inc.',
                                col2: 'token',
                                col3: '.google.com',
                                col4: '2 years',
                                col5: 'This cookie is necessary for sending push notifications and is used to identify a known device.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'kdt',
                                col3: '.twitter.com',
                                col4: '2 years',
                                col5: 'This cookie is for authentication of a known device.',
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'auth_token',
                                col3: '.twitter.com',
                                col4: '5 years',
                                col5: 'This cookie is used for login to the account and authentication.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'twid',
                                col3: '.twitter.com',
                                col4: '5 years',
                                col5: 'This cookie is for authentication.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'ct0',
                                col3: '.twitter.com',
                                col4: '6 Stunden',
                                col5: 'This cookie is for authentication.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'guest_id',
                                col3: '.twitter.com',
                                col4: '2 years',
                                col5: 'This cookie is for authentication.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'des_opt_in',
                                col3: '.twitter.com',
                                col4: '12 months',
                                col5: 'This cookie contains the cookie settings.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'eu_cn',
                                col3: '.twitter.com',
                                col4: '12 months',
                                col5: 'This cookie contains the cookie settings.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: 'dnt',
                                col3: '.twitter.com',
                                col4: '5 years',
                                col5: 'This cookie is for the rejection of personalization of advertisements.'
                            },
                            {
                                col1: 'Twitter Inc.',
                                col2: '_twitter_sess',
                                col3: '.twitter.com',
                                col4: 'Session',
                                col5: 'This cookie is for user sessions.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'usida',
                                col3: '.facebook.com',
                                col4: 'Session',
                                col5: 'This cookie is used for advertising settings.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'fr',
                                col3: '.facebook.com',
                                col4: '14 Tage',
                                col5: 'This cookie is used for advertising settings.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'sb',
                                col3: '.facebook.com',
                                col4: '18 months',
                                col5: 'This cookie identifies the browser.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'presence',
                                col3: '.facebook.com',
                                col4: 'Session',
                                col5: 'This cookie is used for the chat function.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'datr',
                                col3: '.facebook.com',
                                col4: '18 months',
                                col5: 'This cookie identifies the browser.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'c_user',
                                col3: '.facebook.com',
                                col4: '12 months',
                                col5: 'This cookie is for authentication.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'xs',
                                col3: '.facebook.com',
                                col4: '12 months',
                                col5: 'This cookie is used for the secure browsing function.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'mid',
                                col3: '.instagram.com',
                                col4: '18 months',
                                col5: 'This cookie is used for security purposes.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'id_did',
                                col3: '.instagram.com',
                                col4: '18 months',
                                col5: 'This cookie enables the integration of the Instagram widget.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'rur',
                                col3: '.instagram.com',
                                col4: 'Session',
                                col5: 'This cookie is used for security purposes.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'fbsr_*',
                                col3: '.instagram.com',
                                col4: 'Session',
                                col5: 'This cookie is used for security purposes.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'csrftoken',
                                col3: '.instagram.com',
                                col4: '9 months',
                                col5: 'This cookie is used for security purposes.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'ds_user_id',
                                col3: '.instagram.com',
                                col4: '14 Tage',
                                col5: 'This cookie is for authentication.'
                            },
                            {
                                col1: 'Meta Inc.',
                                col2: 'sessionid',
                                col3: '.instagram.com',
                                col4: '9 months',
                                col5: 'This cookie is for authentication.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SIDCC',
                                col3: '.google.com',
                                col4: '12 months',
                                col5: 'Security cookie from Google to authenticate users, prevent fraudulent use of credentials, and protect user data from unauthorized access.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: '1P_JAR',
                                col3: '.google.com',
                                col4: '1 month',
                                col5: 'This cookie is used to optimize advertising, to provide ads that are relevant to users, to improve campaign performance reports, or to prevent a user from seeing the same ads more than once.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'NID',
                                col3: '.google.com',
                                col4: '6 months',
                                col5: ''
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'AEC',
                                col3: '.google.com',
                                col4: '12 months',
                                col5: ''
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SID',
                                col3: '.google.com',
                                col4: '24 months',
                                col5: 'Security cookie from Google to authenticate users, prevent fraudulent use of credentials, and protect user data from unauthorized access.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'APISID',
                                col3: '.google.com',
                                col4: '24 months',
                                col5: 'Security cookie from Google to authenticate users, prevent fraudulent use of credentials, and protect user data from unauthorized access.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'CONSENT',
                                col3: '.google.com',
                                col4: '16 years',
                                col5: 'This cookie contains the cookie settings.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SAPISID',
                                col3: '.google.com',
                                col4: '24 months',
                                col5: 'Security cookie from Google to authenticate users, prevent fraudulent use of credentials, and protect user data from unauthorized access.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SSID',
                                col3: '.google.com',
                                col4: '24 months',
                                col5: 'Security cookie from Google to authenticate users, prevent fraudulent use of credentials, and protect user data from unauthorized access.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SAPISID',
                                col3: '.youtube.com',
                                col4: '24 months',
                                col5: 'Security cookie from Google to authenticate users, prevent fraudulent use of credentials, and protect user data from unauthorized access.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SID',
                                col3: '.youtube.com',
                                col4: '24 months',
                                col5: 'Security cookie from Google to authenticate users, prevent fraudulent use of credentials, and protect user data from unauthorized access.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'APISID',
                                col3: '.youtube.com',
                                col4: '24 months',
                                col5: 'Security cookie from Google to authenticate users, prevent fraudulent use of credentials, and protect user data from unauthorized access.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'SIDCC',
                                col3: '.youtube.com',
                                col4: '12 months',
                                col5: 'Security cookie from Google to authenticate users, prevent fraudulent use of credentials, and protect user data from unauthorized access.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'CONSENT',
                                col3: '.youtube.com',
                                col4: '16 years',
                                col5: 'This cookie contains the cookie settings.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'LOGIN_INFO',
                                col3: '.youtube.com',
                                col4: '18 months',
                                col5: 'This cookie is for authentication.'
                            },
                            {
                                col1: 'Google Inc.',
                                col2: 'PREF',
                                col3: '.youtube.com',
                                col4: '1 month',
                                col5: 'Used by YouTube (Google) to store user preferences and other unspecified purposes.'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'OptanonAlertBoxClosed',
                                col3: '.spotify.com',
                                col4: '12 months',
                                col5: 'This cookie contains the cookie consent settings.'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'OptanonConsent',
                                col3: '.spotify.com',
                                col4: '12 months',
                                col5: 'This cookie contains the cookie consent settings.'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'eupubconsent-v2',
                                col3: '.spotify.com',
                                col4: '12 months',
                                col5: 'This cookie contains the cookie consent settings.'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'sp_dc',
                                col3: '.spotify.com',
                                col4: '12 months',
                                col5: 'This cookie is used to embed the Spotify widget.'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'sp_key',
                                col3: '.spotify.com',
                                col4: '12 months',
                                col5: 'This cookie is used to embed the Spotify widget.'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'sp_m',
                                col3: '.spotify.com',
                                col4: '42 months',
                                col5: 'This cookie is used to embed the Spotify widget.'
                            },
                            {
                                col1: 'Spotify AB',
                                col2: 'sp_t',
                                col3: '.spotify.com',
                                col4: '12 months',
                                col5: 'This cookie is used to embed the Spotify widget.'
                            }
                        ]
                    }
                ]
            }
        }
    }
}