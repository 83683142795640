import api from '@/service/index.js'

/**
 * Returns the content for local notifications
 * Content Provider: Directus (Headless CMS) API
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const localNotification = (qrcodeId) =>
  api.get(`/api/local-notifications/${qrcodeId}`)
