import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import CookieConsent from 'vue-cookieconsent';

import { IonicVue } from '@ionic/vue';

import mitt from 'mitt';
const emitter = mitt();

//@ts-ignore 
import { initCache } from '@/service/cache.js';
initCache()

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/fonts.css';
import './theme/variables.css';
import './theme/additional.css';

/* Cookie consent styles */
import '../node_modules/vue-cookieconsent/vendor/cookieconsent.css';
import './theme/cookieconsent_custom.css';

/* Cookie consent configuration */
import { consentOptions } from './consent-options'

const app = createApp(App)
    .use(i18n)
    .use(CookieConsent, consentOptions)
    .use(IonicVue, {
      rippleEffect: false,
      mode: 'md'
    })
    .use(router);

app.config.globalProperties.$window = window;
app.config.globalProperties.$bus = emitter;

router.isReady().then(() => {
  app.mount('#app');
});
