import { Capacitor } from '@capacitor/core'
import axios from 'axios'

function addEvent (eventName, value) {
    // @ts-ignore
    let _mtm = window._mtm = window._mtm || [];

    if (eventName === 'mtm.PageView') {
        _mtm.push({
            'event': 'mtm.PageView',
            'mtm.newUrlPath': value // {{HistoryHashNewPath}}
        })
    } else {
        let index = _mtm.findIndex(e => e.event === eventName)
    
        if (index === -1) {
            let event = {}
            event.event = eventName
            event[eventName] = value
            _mtm.push(event)
        } else {
            _mtm[index][eventName] = value
        }
    }
}

export default {
    setAppEnvironment () {
        let env = 'PWAOnly'; // PWA

        if (Capacitor.getPlatform() === 'ios') {
            env = 'iOS'
        } else if (Capacitor.getPlatform() === 'android') {
            env = 'Android'
        }

        console.log('[Matomo helper] appEnvironment: ' + env)
        addEvent('appEnvironment', env)   
    },
    setAppVersion () {
        const appVersion = require('../../package.json').version
        console.log('[Matomo helper] appVersion: ' + appVersion)
        addEvent('appVersion', appVersion)  
    },
    setLoggedInStatus () {
        const loggedIn = !localStorage.getItem('jwt') ? 0 : 1
        console.log('[Matomo helper] loggedIn: ' + loggedIn)
        addEvent('loggedIn', loggedIn)
    },
    trackPageViewViaTagManager (newUrlPath) {
        console.log('[Matomo helper] track page view for ' + newUrlPath)
        addEvent('mtm.PageView', newUrlPath)
    },
    trackViaHTTPApi(urlPath, params) {
        let env = 'PWAOnly'; // PWA
        if (Capacitor.getPlatform() === 'ios') {
            env = 'iOS'
        } else if (Capacitor.getPlatform() === 'android') {
            env = 'Android'
        }

        const defaultParams = [
            'idsite=8', // matomo site id
            'rec=1',    // must be set, required by HTTP API
            `url=${encodeURIComponent('https://app.bundeskunsthalle.de' + urlPath)}`, // current path
            `dimension2=${require('../../package.json').version}`, // custom dimension 2: app version
            `dimension1=${!localStorage.getItem('jwt') ? 0 : 1}`, // custom dimension 1: login status
            `dimension5=${env}` // custom dimension 5: platform type (PWA, Android or iOS)
        ]

        params = [
            ...defaultParams,
            ...params
        ]

        const url = `https://www.bundeskunsthalle.de/statistik/matomo.php?${params.join('&')}`

        console.log('[Matomo helper] tracking via HTTP API ' + url)
        axios.get(url).catch(error => { 
            console.log('[Matomo helper] error while tracking via HTTP API: ' + error)
        })
    },
    trackPageView (urlPath) {
        this.trackViaHTTPApi(urlPath, [])
        // addEvent('mtm.PageView', urlPath)
    },
    trackSearchPageView(keyword, numResults) {
        const params = [
            `search=${encodeURIComponent(keyword)}`,
            `search_count=${numResults}`
        ]
        this.trackViaHTTPApi('/search', params)
    },
    trackNewsletterSubscriptionChange(status) {
        const params = [
            `e_c=Newsletter`,
            `e_a=Subscription_Update`,
            `e_n=${!status ? 'Unsubscribe' : 'Subscribe'}`,
            `e_v=1`
        ]
        this.trackViaHTTPApi('/account', params)
    },
    trackMediaGuideDownload(urlPath, status, id) {
        const params = [
            `e_c=MediaGuide`,
            `e_a=Download`,
            `e_n=${!status ? 'Delete' : 'Download'}`,
            `e_v=${id}`
        ]
        this.trackViaHTTPApi(urlPath, params)
    }
}
