import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_alert = _resolveComponent("ion-alert")!

  return (_openBlock(), _createBlock(_component_ion_alert, {
    "is-open": _ctx.showUpdateModal,
    header: _ctx.$t('updateAppModal.title'),
    message: _ctx.$t('updateAppModal.text'),
    "css-class": "my-custom-class update-modal",
    buttons: _ctx.buttons,
    onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showUpdateModal(false)))
  }, null, 8, ["is-open", "header", "message", "buttons"]))
}