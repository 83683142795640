import api from '@/service/index.js'

/**
 * Requests the tracking of interests, related to the given QR Code, within the CRM.
 * This can only be done by the logged in user.
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const touchpointInteraction = (id, type, firebaseDeviceId) =>
  api.post(`/api/users/me/touchpoint-interaction`,
    {
      id: id,
      type: type,
      firebaseDeviceId: firebaseDeviceId
    }
  )

/**
 * Permanently deletes the current user's account.
 * This can only be done by logged in users.
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const deleteAccount = () =>
  api.delete(`/api/users/me`)

/**
 * Get the current user's CRM profile
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getCrmUser = () =>
  api.get(`/api/users/me`)

/**
 * Patch the current user's CRM profile
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const patchCrmUser = (payload) =>
  api.patch(`/api/users/me`, payload)

/**
 * Get the list of interest groups for the current user's CRM profile
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getUserInterestGroups = () =>
  api.get(`/api/users/me/interest-groups?noCache=1`)

/**
 * Saves a list of interest groups for the current user's CRM profile
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const saveUserInterestGroups = (payload) =>
  api.post(`/api/users/me/interest-groups`, payload)