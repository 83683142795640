import { Browser } from '@capacitor/browser'

export default {
  getIconSrc (icon) {
    return require(`@/assets/img/icons/${icon}.svg`)
  },
  getAsset(file) {
    return require(`@/assets/${file}`)
  },
  async openCapacitorSite(targetUrl) {
    if (targetUrl.includes("http://") || targetUrl.includes("https://")) {
      return await Browser.open({ url: targetUrl || "" });
    } else {
      if (!targetUrl.includes('/tabs')) {
        targetUrl = `/tabs${targetUrl}`
      }
      this.$router.push({ path: targetUrl });
    }
  }
}
