import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "qr-popover" }
const _hoisted_2 = { class: "mt-1" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title || _ctx.content)
      ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
          default: _withCtx(() => [
            (_ctx.title)
              ? (_openBlock(), _createBlock(_component_ion_list_header, {
                  key: 0,
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_text, { color: "dark" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.content)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 1,
                  lines: "none"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "ion-item-content",
                      innerHTML: _ctx.content
                    }, null, 8, _hoisted_3)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}