import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_update_modal = _resolveComponent("update-modal")!
  const _component_offline_banner = _resolveComponent("offline-banner")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass({'app-offline': !_ctx.isOnline})
      }, [
        (_ctx.versionOutdated)
          ? (_openBlock(), _createBlock(_component_update_modal, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_offline_banner),
        _createVNode(_component_ion_router_outlet, { animated: "true" })
      ], 2)
    ]),
    _: 1
  }))
}