import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!

  return (_openBlock(), _createBlock(_component_ion_tabs, {
    class: "app-navigation",
    onIonTabsDidChange: _cache[0] || (_cache[0] = ($event: any) => ($options.afterTabChange($event))),
    ref: "appNav"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_router_outlet),
      _createVNode(_component_ion_tab_bar, {
        slot: "bottom",
        class: "ion-bg-black"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.navigation, (button, index) => {
            return (_openBlock(), _createBlock(_component_ion_tab_button, {
              tab: button.tab,
              key: `app-navigation-${index}`,
              href: button.href,
              onClick: ($event: any) => ($options.checkTab(button.tab))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.getIconSrc(button.iconName)
                }, null, 8, ["src"]),
                _createVNode(_component_ion_label, {
                  class: "ff-bonn-plus",
                  innerHTML: _ctx.$t(button.text)
                }, null, 8, ["innerHTML"])
              ]),
              _: 2
            }, 1032, ["tab", "href", "onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}