import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n';

function loadLocaleMessages(): { [x: string]: LocaleMessages<VueMessageType> } {
  const locales = require.context('../locales', true, /([a-z]{2})+[_]+([A-Z]{2})+(\.buku)?\.json$/)
  const messages: { [x: string]: LocaleMessages<VueMessageType> } = {}

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function getDeviceLanguage() {
  let storageLocale = localStorage.getItem('locale') || null
  if(storageLocale) {
    console.log('has localStorage language ', storageLocale);
    document.documentElement.setAttribute('lang', storageLocale.split('_')[0])
    return storageLocale;
  } else {
    console.log('has no localStorage language');
    if (window.Intl && typeof window.Intl === 'object') {
      const language = navigator.language.toLowerCase();
      if (['de','de-de','de-li','de-lu','de-at','de-ch'].includes(language)) {
        console.log('navigaton switch case ', language);
        storageLocale = 'de_DE';
        localStorage.setItem('locale', storageLocale);
        document.documentElement.setAttribute('lang', 'de')
        return storageLocale;
      } else {
        console.log('navigaton switch case default en-EN');
        storageLocale = 'en_EN';
        localStorage.setItem('locale', storageLocale);
        document.documentElement.setAttribute('lang', 'en')
        return storageLocale;
      }
    } else {
      return process.env.VUE_APP_I18N_LOCALE || 'en_EN';
    }
  }
}

const i18n = createI18n({
  legacy: false,
  locale: getDeviceLanguage(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en_EN',
  messages: loadLocaleMessages()
})

export default i18n;